import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { ServicesService } from '../../shared/services/services.service';
import { LoginUserComponent } from '../login/login-user/login-user.component';
import { DetailProductComponent } from '../../shared/detail-product/detail-product.component';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPrintModule } from 'ngx-print';

let apiLoaded = false;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  //urlRutaImagenFirebase
  @ViewChild("modalFinalCustomer") modalFinalCustomer: TemplateRef<any> | undefined;

  rutaUrl=environment.firebaseUrl;
  flagLoader=false;
  public configuracion: any = {};
  public configuration: any = {};
  public information: any = {};
  public informacion: any = {};
  public login: any = {};
  public client: any = {};
  public products: any = [];
  public productsTemp: any = [];
  public productsTemp2: any = [];
  public productsSold: any = {};
  public configurationVariables = {
    tipo_precio: 'pA', // No se esta usando
    mostrar_precio: 1,
    show_attributes_prod: false,
  }
  public loadingAll = false;
  public productSelected: any;
  public closeResult: string;
  public guarnition: any = [];
  public productDefault=[];
  public tipoNegocio:any;
  public tipoLogin='';
  public allGrupos:any=[];
  tipoDefault=0;
  flagCarLocal=false;
  private storageSubscription!: Subscription;
  comanda:any=[];
  prods : any =[];
  url:any;
  flagMakeComanda=false;
  tiposPago:any=[];
  banderaAC = false;
  id_mesero:any;
  id_mesa:any;
  resultadoComanda:any=[];
  contactoFinal:any;
  idComanda:any;
  idEmpresa=environment.idShop;
    // --------------------------------- MODALES -----------------------------------------
    @ViewChild("modalPrintComanda") modalPrintComanda: TemplateRef<any> | undefined;

  constructor(
    private toaster: ToastrService,
    private webService: ServicesService,
    private activateRoute: ActivatedRoute,
    private modalCtrl: NgbModal,
    private meta: Meta,
    private router: Router,
    private printM : NgxPrintModule
  ) {
    // this.showProducts();

  }

  async ngOnInit() {
    let carLocal = parseInt(this.webService.getFromLocalStorage('carLocal'));
    console.log('card LOCAL', carLocal);
    if(carLocal==1){
     this.flagCarLocal=true;
     this.prods = this.webService.getFromLocalStorage('carrito');
    }
    this.tipoDefault = this.webService.getFromLocalStorage('tipoCliente');
        // Cargar atributos para el video de youtube
    const tag = await document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    await document.body.appendChild(tag);
    apiLoaded = true;
    let url;
    await this.getConfiguracion();
    await this.getInformation();
    
    await this.webService.getUrlEmpresa().then(async (url_billing) => {
      this.url = this.modificarURL(url_billing);
      url = url_billing;
    });
    await this.activateRoute.params.subscribe(async (params: Params) => {
      this.products = [];
      console.log('ENTRA AQUI PRIMERO', params);

      this.loadingAll = true;
      await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (reslogin: any) => {
        this.login = reslogin;
      });
      console.log('params value', params.value);
      
      if (params.value) {
        if (params.type == 'search') {
          let search = params.value;
          await this.webService.searchProduct(url, search, this.configuracion).then(async (ressearch: any) => {
            if (ressearch.rta == true) {
              this.products = [];
              if (this.configuracion.tipo_web == 1) {
                await this.webService.obtainAndCalculatePriceProduct(ressearch.data, this.configuracion, this.login).then(async (resPrice) => {
                  this.products = resPrice;
                  this.productsTemp = resPrice;
                  this.productsTemp2 = resPrice;
                  // console.log("search", this.products);
        
                });
              }
              if (this.configuracion.tipo_web == 2) {
                await this.webService.obtainAndCalculatePriceProduct(ressearch.data, this.configuracion, this.login).then(async (resPrice) => {
                  await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                    this.products = resTalla;
                    this.productsTemp = resTalla;
                    this.productsTemp2 = resTalla;
                  });
                });
              }
            } else {
              search = '';
              this.toaster.warning('No se ha encontrado coincidencias', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        }

        if (params.type == 'selectCatalogue') {
          this.loadingAll=true;
          this.flagLoader=true;
          this.webService.getProductosService(url, params.value, params.value2, this.configuracion).then(async (resprod: any) => {
            if (resprod.rta == true) {
              if (this.configuracion.tipo_web == 1) {
                await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                  this.products = resPrice;
                  this.productsTemp = resPrice;
                  this.productsTemp2 = resPrice;

                  this.loadingAll=false;
                  this.flagLoader=false;

                  // console.log("seleccionado", this.products);
                });
              }

              if (this.configuracion.tipo_web == 2) {
                await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                  await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                    this.products = resTalla;
                    this.productsTemp = resTalla;
                    this.productsTemp2 = resTalla;
                    this.loadingAll=false;
                    this.flagLoader=false;
                  });
                });
              }
            } else {
              this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        }

        if (params.type == 'selectProduct') {
          await this.webService.getProductosCodigoService(url, params.value, this.configuracion).then(async (resprod: any) => {
            if (resprod.rta == true) {
              if (this.configuracion.tipo_web == 1) {
                await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                  this.products = resPrice;
                  this.productsTemp = resPrice;
                  this.productsTemp2 = resPrice;
                  // console.log("llamado", this.products);
                });
              }
              if (this.configuracion.tipo_web == 2) {
                await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                  await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                    this.products = resTalla;
                    this.productsTemp = resTalla;
                    this.productsTemp2 = resTalla;
                    console.log("ACA", this.products);
                  });
                });
              }
            } else {
              this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        }

      } else {
        console.log('ENTRA ACA', this.allGrupos);
        this.flagLoader=true;
       setTimeout(() => {
        let idGroup =   this.allGrupos[0].idgrupo;
        console.log('idGroup', idGroup);
        let idSubGroup =  this.allGrupos[0]?.subgrupos[0]?.id_sub;      
          // console.log('idSubGroup', idSubGroup);
         
          
          if (this.allGrupos) {
 
            console.log(this.configuracion);
            
             this.webService.getProductosService(url,  idGroup,   idSubGroup, this.configuracion).then(async (resprod: any) => {
              if (resprod.rta == true) {
                if (this.configuracion.tipo_web == 1) {
                  await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                    this.products = resPrice;
                    console.log("seleccionado", this.products);
                    this.productsTemp = resPrice;
                    this.productsTemp2 = resPrice;
                  });
                }
                if (this.configuracion.tipo_web == 2) {
                  await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                    await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                      this.products = resTalla;
                      this.productsTemp = resTalla;
                      this.productsTemp2 = resTalla;
                      // await this.webService.deleteProductsDuplicated(resTalla, 'tagDeGrupo').then(async (resProd) => {
                      //   this.products = resProd;
                      //   // console.log("seleccionado", this.products);
                      // });
                    });
                  });
                }
              } else {
                this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          } else {
             this.webService.getGruposService(url, 'filter').then(async (resCategorias: any) => {
              // console.log('Antes de la primera',resCategorias );
              
              if (!resCategorias.error) {
                if (resCategorias.rta == true) {
                  await this.webService.orderObjectsAsc(resCategorias.data).then(async (resorderg) => {
                    resCategorias.data = resorderg;
                    // console.log('Primera ==> ',resCategorias );
                    
                  });
                  await this.getSubgrupos(resCategorias.data, url).then(async (resSubgrup: any) => {
                    console.log('subgrupos ===>' , resSubgrup );
                    
                    await this.webService.orderObjectsAsc(resSubgrup).then(async (resorder) => {
                      await this.webService.getProductosService(url, resorder[0].idgrupo, resorder[0].subgrupos[0].id_sub, this.configuracion).then(async (resprod: any) => {
                        console.log('SUVB ', resprod);
                        
                        if (resprod.rta == true) {
                          if (this.configuracion.tipo_web == 1) {
                            await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                              this.products = resPrice;
                              this.productsTemp = resPrice;
                              this.productsTemp2 = resPrice;
                              console.log("seleccionado", this.products);
                            });
                          }
                          if (this.configuracion.tipo_web == 2) {
                            await this.webService.obtainAndCalculatePriceProduct(resprod.data, this.configuracion, this.login).then(async (resPrice) => {
                              await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                                this.products = resTalla;
                                this.productsTemp = resTalla;
                                this.productsTemp2 = resTalla;
                                // await this.webService.deleteProductsDuplicated(resTalla, 'tagDeGrupo').then(async (resProd) => {
                                //   this.products = resProd;
                                //   // console.log("seleccionado", this.products);
                                // });
                              });
                            });
                          }
                        } else {
                          this.toaster.warning('No se ha encontrado productos', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                        }
                      });
                      await this.webService.saveTemporaryCatalogue(resorder);
                    });
                  });
                } else {
                  this.toaster.warning('Catálogo de la tienda vacio', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                  this.flagLoader=false;
                }
              } else {
                this.toaster.error('Error al obtener las categorias', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          }
          this.flagLoader=false;

       }, 1200); 
      //  await this.webService.getTemporaryCatalogue().then(async(rescat: any) => {
      //   console.log('log RESCAT', await rescat);

      //   });
      }
      await this.webService.goUpPage();
      this.loadingAll = false;
      this.flagLoader=false;
    });
    await this.webService.observableFilterProducts().subscribe(async (res) => {
      this.loadingAll = true;
      await this.webService.orderProductPriceAscDesc(this.products, res).then((resorder) => {
        this.products = resorder;
        this.productsTemp = resorder;
        this.productsTemp2 = resorder;
      });
      this.loadingAll = false;
    });
    await this.webService.getProductPopularSoldService(url, this.configuracion).then(async (respopular: any) => {
      if (respopular.rta == true) {
        await this.webService.obtainAndCalculatePriceProduct(respopular.data, this.configuracion, this.login).then(async (resprice) => {
          this.productsSold = resprice;
          this.productsTemp = resprice;
          this.productsTemp2 = resprice;
        });
      }
    });


    // this.storageSubscription  = this.webService
    // .getStorageObservable()
    // .subscribe((data) => {
    //   if(data.key=='carLocal'){
    //     if(data.data=='1'){
    //       this.flagCarLocal=false;
    //     }
    //   }else{
    //     this.flagCarLocal= true;
    //   }
    //   console.log('data',data);
      
    // });


  }

  
  recibeGrupoSubGrupo(event:any){
    console.log('LLEGA LLEGA ', event);
    
    this.allGrupos= event;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async showProducts() {
    await this.webService.observableProducts().subscribe(async (resprod) => {
      this.products = resprod;
      this.productsTemp = resprod;
      this.productsTemp2 = resprod;
      console.log("suscrito", this.products);
    });
  }

  async getConfiguracion() {
    this.loadingAll=true;
    await this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        this.configuracion = data[0];
        this.configuration = data[0];
        console.log(' ==============================',this.configuracion);
        const rgbaColor = this.webService.hexToRgba(this.configuracion.colorPrincipal, 0.2);
        document.documentElement.style.setProperty('--lighter-tone', rgbaColor);
     
        
        

        // Mostrar todo el catalogo de productos
        // document.getElementById("aside-catalogo").style.height = "100%";
        await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then((resauth: any) => {
          if (resauth.rta == true) {
            this.configurationVariables.mostrar_precio = 1;
          } else {
            this.configurationVariables.mostrar_precio = this.configuracion.mostrar_precio;
          }
        });
        this.configurationVariables.show_attributes_prod = this.webService.showAtttibutesProducts(this.configuracion);
        this.loadingAll=false;
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }
  async getInformation() {
    await this.webService.getInformacion().then(async (data: any) => {
      if (!data.error) {
        if (data[0]) {
          this.information = data[0];
          this.informacion = data[0];
          console.log("Informacion", this.information);
          // this.formLogoDos.controls['url'].setValue(this.information.redirect_logo_dos)
        } else {
          console.log("No se ha encontrado information");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }


  async getSubgrupos(grupos, url_billing) {
    this.loadingAll = true;
    // for (let g of grupos) {
    //   await this.webService.getSubgruposService(url_billing, g.idgrupo).then(async (resSubgrup: any) => {
    //     await this.webService.orderObjectsAsc(resSubgrup.data).then(async (resorder) => {
    //       g.subgrupos = await resorder;
    //     });
    //   });
    //   // break;
    // }
          await this.webService.getSubgruposService(url_billing, grupos[0].idgrupo).then(async (resSubgrup: any) => {
          this.webService.orderObjectsAsc( await resSubgrup.data).then(async (resorder) => {
          grupos[0].subgrupos[0] = await resorder;
          console.log('grupos ======================>', grupos);
        });
      });
   
      
    this.loadingAll = false;
    return grupos;
  }

  async modalViewDetailProduct(product) {
    console.log('MODAL 1 o 2');
    
    // Informacion envida al modal
    let send = {
      product: product,
      configuracion: this.configuracion,
      show_price: this.configurationVariables.mostrar_precio,
      show_attributes_product: this.configurationVariables.show_attributes_prod,
      login: this.login,
      productsSold: this.productsSold
    }
    this.webService.setProductSelectedDetail(send);

    this.modalCtrl.open(DetailProductComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'xl' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log("Close", result);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log("Dismiss", reason);
      if(this.information.esPuntoVenta==1){
        if(reason.type='acept'){
          this.setMethodAddCart(reason.data, this.information.esPuntoVenta,0);
         }
         this.webService.setProductSelectedDetail({});
      }else{
        this.webService.setProductSelectedDetail({});
      }
    });
  }

  async loginClient() {
    console.log('lo llama');
    
    this.modalCtrl.open(LoginUserComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
    this.modalCtrl.dismissAll
      // this.modalCtrl.dismissAll('home');
      // this.modalCtrl.activeInstances.subscribe((data:any)=>{
      //   console.log('data',data);
        
      //   this.tipoLogin= 'home';
      
      // })
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log(reason);
    });
  }


  // Aqui hacerle que vea si abre el modal detalle producto o agrega directo al carrito
  // ***** ******
  public setMethodAddCart(product, esPunto, tipo) {
    console.log('es punto venta', esPunto);
    console.log('es tipo', tipo);
    this.tipoNegocio=esPunto;
    this.productDefault=product;
    this.tipoDefault=tipo;
    this.webService.saveToLocalStorage('tipoCliente', this.tipoDefault);
    if( esPunto==1){
      console.log('ENTRA A ESTE METODO 1');
      let sessionInit = localStorage.getItem('isLoged');
      console.log('sessionInit', sessionInit); 
      if(sessionInit=='true'){
        //  this.addProductShoppingCart(product, login.data);
        this.verifyLoginClientFinal(product, 'direct',tipo);
      }else{
        this.verifyLoginClientFinal(product, 'direct',tipo);
      }
    }else{
      console.log('ENTRA A ESTE METODO 2');
      // if (product.talla || product.guarnicion == true) {
      //   this.modalViewDetailProduct(product);
      // } else {
      //   this.verifyLoginClient(product, 'direct',tipo);
      // }
              this.verifyLoginClient(product, 'direct',tipo);

    }
  }

  // De aqui siempre se va agregar el producto al carrito
  async verifyLoginClient(product, type,tipo) {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        this.client = login.data;
        if (type == 'direct') {
          product.quantity = 1;
        }
        await this.addProductShoppingCart(product, login.data);
      } else {
        await this.loginClient();
      }
    });
  }
  // async verifyLoginClientFinal(product, type) {
  //   await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
  //     if (login.rta == true) {
  //       this.client = login.data;
  //       if (type == 'direct') {
  //         product.quantity = 1;
  //       }
  //       await this.addProductShoppingCart(product, login.data);
  //     } else {

  //       // this.setMethodAddCart(product,1)
  //               this.modalCtrl.open(this.modalFinalCustomer, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' })

  //       // let login={
  //       //   usuario:'9999999999',
  //       //   clave:'9999999999'
  //       // }
  //       // await this.loginClient2(`https://sofpymes.com/${environment.empresa}/common/movil/`, this.configuracion, login, 'login');
  //     }
  //   });
  // }

  async verifyLoginClientFinal(product, type,tipo) {
    console.log('TIPO QUE ENTRA === > ', tipo);
    this.tipoDefault=tipo;
    this.webService.saveToLocalStorage('tipoCliente', this.tipoDefault);
    await this.webService.isAuthenticatedClient(this.configuration.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        this.client = login.data;
        if (type == 'direct') {
          product.quantity = 1;
        }
        console.log("viene aqui ----: Acá debo agregar ", );
        
        await this.addProductShoppingCart(product, login.data);
      } else {
        console.log('ABRE MODAL PARA LOGUEAR');
        
        // this.setMethodAddCart(product,1)
                this.modalCtrl.open(this.modalFinalCustomer, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' })

        // let login={
        //   usuario:'9999999999',
        //   clave:'9999999999'
        // }
        // await this.loginClient2(`https://sofpymes.com/${environment.empresa}/common/movil/`, this.configuracion, login, 'login');
      }
    });
  }


  async addProductShoppingCart(product, client) {
    console.log('ENTRA PARA AGREGAR');
    let counter = 0; 
    let urlBilling = `https://sofpymes.com/${environment.empresa}/common/movil/`;
    this.loadingAll=true;
    if (product.precioReal > 0) {
      this.loadingAll = true;
      await this.webService.addProductCartSecond(product, client, this.configuration, urlBilling ).then(async (res: any) => {
        console.log('respuesta agrega primero ==> ', res);
        
        if (res.rta == true) {
          this.toaster.success(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          if(localStorage.getItem('carCount')){
            counter = parseFloat(localStorage.getItem('carCount')) ;
          }
          counter += 1;
          // localStorage.setItem('carCount', JSON.stringify(counter));
          this.webService.saveToLocalStorage('carCount', counter);
        } else {
          this.toaster.warning(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
      this.loadingAll = false;
      

    } else {
      this.toaster.warning('No se puede agregar el producto, precio no válido', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      this.loadingAll = false;
    }

  }

  async sharedFacebook(product) {
    let url = this.configuracion.dominioPagina + '/product/selectProduct/' + product.id_producto + '/' + 0;
    // let url = 'http://www.localhost:4200/product/selectProduct/' + product.id_producto + '/' + 0;
    let shared = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
    window.open(shared, 'sharer', '_blank');
  }
  closeModal(){
    localStorage.setItem('carCount','0');
    // localStorage.setItem('tipoCliente','0');
    this.modalCtrl.dismissAll();

      }
  
  loginFinal(user,pass){
    let login={
      usuario:user,
      clave:pass
    }
    // this.verifyLoginClientFinal(this.productDefault, 'direct');

    this.loginClient2(`https://sofpymes.com/${environment.empresa}/common/movil/`, this.configuracion, login, 'login')

  }
  // https://sofpymes.com/demorestaurante/common/movil/login_user?usuario=9999999999&clave=9999999999
  async loginClient2(url_billing, configuracion, login, type) {
    if (login.usuario && login.clave) {
      if (type == 'login') {
        this.toaster.info('Espere un momento ...', '', { timeOut: 1000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
      await this.webService.loginCliente(url_billing, login, 'login').then(async (resclient: any) => {
        if (!resclient.error) {
          if (resclient.rta == true) {
            await this.webService.saveUserLocalStorage(resclient.data[0], configuracion.loginStorage, "Client").then(async (resauth: any) => {
              await this.webService.getproductsCart({ id_cliente: resauth.data.PersonaComercio_cedulaRuc }).then(async (resprod: any) => {
                if (resprod.rta == true) {
                  let observable = {
                    number: resprod.data.length,
                    total: await this.webService.calculateTotalCartProducts(resprod.data)
                  }
                  this.webService.shopcart$.next(observable);
                }
              });
              await this.webService.refreshPage(configuracion);
              this.toaster.success('Bienvenid@, ' + resclient.data[0].nombres, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            });
          } else {
            // if (resclient.code == 406) {
            //   this.toaster.warning('Contraseña incorrecta, Intente nuevamente o Proceda a recuperarla', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            //   this.dismissModal('Recover');
            // } else {
            //   this.toaster.warning('Usuario no encontrado, Registrelo.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            //   this.dismissModal('Registrar');
            // }
          }
        } else {
          this.toaster.error('Ha ocurrido un error, Intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    } else {
      this.toaster.warning('Ingrese sus credenciales', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }
  
  calculateDescount(cantR, cantD){
    let percent = (parseFloat(cantD)*100)/parseFloat(cantR);
    let desc =  100 - percent;
    desc = Number(desc.toFixed(1))
    return desc
   }

   isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }
  searchProduct(event){
    let texto =  event.target.value;
    console.log('texto', texto);
    console.log('this.productsTemp.', this.productsTemp);
    console.log('this.productsTemp2.', this.productsTemp2);


    if(texto!=''){
      texto = texto.toLowerCase();
      console.log("ENTRA AQUI ");
      
      this.products = this.productsTemp.filter(item=>{
        return (item.pro_nom.toLowerCase()) 
                .includes(texto)
      }) 
    }else{
      this.products = this.productsTemp2;
    }
  }

  AddProductCarLocal(){
    this.loadingAll=true;
    setTimeout(() => {
      Swal.fire({
        position: "top",
        icon: "success",
        title: "Ahora ya puedes agregar productos!",
        showConfirmButton: false,
        timer: 1000
      });
      this.loadingAll=false;

      this.webService.saveToLocalStorage('carLocal', '1');
    
      let carLocal = parseInt(this.webService.getFromLocalStorage('carLocal'));
      console.log('card LOCAL', carLocal);
      if(carLocal==1){
       this.flagCarLocal=true;
      }else{
        this.flagCarLocal=false;
      }
      this.modalCtrl.dismissAll();
   
    }, 1000);

  }

  openModal(modal){
    if(this.prods.length==0){
      this.toaster.error('Su orden no cuenta aún con productos', '', { timeOut: 3000, positionClass: 'toast-top-full-width', closeButton: true, progressBar: true });

    }else{
      this.modalCtrl.open(modal, {size:'xl'})

    }
  }

  makeComanda(prod){
    this.prods =  this.actualizarCantidad(this.prods, prod.nombre_producto, prod.id_producto, prod.precios[0].valor_mas_iva);
    this.webService.saveToLocalStorage('carrito', this.prods);
    console.log('prod select', prod);
    console.log('elementos', this.prods);
      
  }

 

  actualizarCantidad(productos: any[], nombre, id_producto: string, valor_mas_iva): any {
    // Buscar el producto en el arreglo
    const productoExistente = productos.find(producto => producto.id_producto === id_producto);
  
    if (productoExistente) {
      // Si el producto existe, sumar uno a la cantidad
      productoExistente.cantidad += 1;
    } else {
      // Si el producto no existe, agregarlo al arreglo con cantidad 1
      productos.push({
        nombre_producto: nombre,
        id_producto: id_producto,
        cantidad: 1,
        valor: valor_mas_iva, // Asignar el mismo valor que el id_producto
        detalle: 0 // Asignar un valor por defecto o modificar según necesidad
      });
      this.toaster.success('Producto agregado ', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });

    }
  
    return productos;
  }
  actualizarCantidadRestar(productos: any[], nombre, id_producto: string, valor_mas_iva): any {
    // Buscar el producto en el arreglo
    const productoExistente = productos.find(producto => producto.id_producto === id_producto);
  
    if (productoExistente) {
      // Si el producto existe, sumar uno a la cantidad
      productoExistente.cantidad -= 1;
    } else {
      // Si el producto no existe, agregarlo al arreglo con cantidad 1
      productos.push({
        nombre_producto: nombre,
        id_producto: id_producto,
        cantidad: 1,
        valor: valor_mas_iva, // Asignar el mismo valor que el id_producto
        detalle: 0 // Asignar un valor por defecto o modificar según necesidad
      });
      this.toaster.success('Producto agregado ', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });

    }
  
    return productos;
  }

  totalComanda(prods){
    let total = 0;
    for(let prod of prods){
      total += prod.valor*prod.cantidad;
    }
    return total
  }
  totalIvaComanda(prods){
    let iva = 0;
    for(let prod of prods){
      iva += (prod.valor*prod.cantidad) - ((prod.valor*prod.cantidad)/1.15);
    }
    return iva
  }
  numberFourDecimal(numero: number): number {
    // // ////console.log (numero);
    let resul = Number(numero.toFixed(4));
    // // ////console.log ('res', resul);
    return resul;
  }

  quitProductComanda(prod){
    this.prods= this.eliminarProducto(this.prods, prod.id_producto );
    this.webService.saveToLocalStorage('carrito', this.prods);

    if(this.prods.length==0){
      this.closeModal();
    }
  }
  eliminarProducto(productos: any[], id_producto: string): any[] {
  // Filtrar el arreglo para eliminar el producto con el id_producto dado
  return productos.filter(producto => producto.id_producto !== id_producto);
}

// -------------------------- MODAL FUNCIONES ------------------------------

addProductCar(prod){
   this.prods= this.actualizarCantidad(this.prods, prod.nombre_producto, prod.id_producto,prod.valor );   
   this.webService.saveToLocalStorage('carrito', this.prods);

}
quitProduct(prod){
   this.prods= this.actualizarCantidadRestar(this.prods, prod.nombre_producto, prod.id_producto,prod.valor );  
   this.webService.saveToLocalStorage('carrito', this.prods);
 
}

emptyCar(){
  this.closeModal();
  this.prods=[];
  this.webService.saveToLocalStorage('carrito', this.prods);

}

exitLoginLocal(){
  Swal.fire({
    title: "¿Está seguro de cerrar el pedido?",
    text: "Una vez cierre, la información de su pedido se perderá!",
    icon: "error",
    showCancelButton: true,
    confirmButtonColor: "#808080",
    cancelButtonColor: "#ee917e",
    confirmButtonText: "Si, cerrar!",
    cancelButtonText: "Cancelar"
  }).then((result) => {
    if (result.isConfirmed) {
    //   Swal.fire({
    //     title: "Listo!",
    //     text: "Saliendo ",
    //     icon: "success",
    //     showConfirmButton:false,
    //   });
      this.prods=[];
      this.webService.saveToLocalStorage('carrito',this.prods)
      this.webService.saveToLocalStorage('carLocal','0')
      this.flagCarLocal=false;
    }
  });

}

modificarURL(url: string): string {
  const partes = url.split('/');
  if (partes.length > 4) {
    return partes.slice(0, 4).join('/') + '/';
  }  
  return url;
}

formComanda= new FormGroup({
    contacto:new FormControl('', Validators.required ),
    pago:new FormControl(),
    principal:new FormControl(''),
    secundaria:new FormControl(''),
    referencia:new FormControl(''),
})

hacerComanda(){

  this.webService.getGeneral2(this.url+'pventa_granel/api_restaurant/init_ecommerce').subscribe((data:any)=>{
    console.log('result ==>', data);
    if(data.rta){
      this.flagMakeComanda=true;
      this.id_mesa=data.mesa.mesa_id;
      this.id_mesero=data.mesero.id;
    this.webService.getGeneral2(this.url+'pventa_granel/api_restaurant/tipos_pago').subscribe((data2:any)=>{
      console.log('result tiposPAGO', data2);
        this.tiposPago=data2.formaspago;
        this.formComanda.controls['pago'].setValue(this.tiposPago[0].cod);
    })

    }else{
      this.toaster.error(`${data.msg}`, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  })
}

returnPagoCar(){
  this.flagMakeComanda=false;

}


comandaFinal(form){
  console.log('prods ==>', this.prods);
  
  if (this.loadingAll) {
    return;
  }
  this.banderaAC = true;
  this.loadingAll=true;
  let product = {
    "id_producto": "",
    "cantidad": 0,
    "valor": 0.0,
    "detalle": ""
  }
  let detalle_comanda = [];
  for(let prod of this.prods){
    product = {
      "id_producto":  prod.id_producto,
      "cantidad": prod.cantidad,
      "valor": prod.valor,
      "detalle": prod.detalle
    }
    // product.id_producto = prod.id_producto,
    // product.cantidad = prod.cantidad,
    // product.valor = prod.valor,
    // product.detalle = prod.detalle
    detalle_comanda.push(product);
  }
 

  console.log('ASI QUEDA',detalle_comanda);
  this.contactoFinal= form.contacto;
  let comanda = {
  	"id_mesero": this.id_mesero,
		"user": this.id_mesero,
		"subtotal":this.numberFourDecimal( this.totalComanda(this.prods)),
		"subtotal0": "0.00",
		"subtotal12":this.numberFourDecimal(this.totalComanda(this.prods) - this.totalIvaComanda(this.prods)),
		"servicios":this.numberFourDecimal(this.totalComanda(this.prods) - this.totalIvaComanda(this.prods)),
		"iva12": this.numberFourDecimal(this.totalIvaComanda(this.prods)) ,
		"estado": 2,
		"observaciones": "Local",
		"tipoprecio": "pA",
		"cliente": 9999999999,
		"id_mesa": this.id_mesa,
		"contacto": form.contacto,
		"pago": form.pago,
		"principal": form.principal,
		"secundaria": form.secundaria,
		"referencia": form.referencia
  }
  let json = {
    comanda:comanda,
    detalle_comanda: detalle_comanda
  }

  // ================================================== CASO 1  ==================================================
  // this.modalCtrl.dismissAll();
  // this.banderaAC = false;
  // this.loadingAll = false;
  // this.modalCtrl.open(this.modalPrintComanda, {size:'sm', backdrop: 'static', keyboard: false})

  this.webService.postGeneral2(this.url+'pventa_granel/api_restaurant/insert_comanda_ecommerce', json).subscribe((data:any)=>{
    console.log('data',data);
    // this.resultadoComanda = data;
    if(data.rta){
      this.toaster.success('Pedido generado exitosamente ', '', { timeOut: 5000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      this.modalCtrl.dismissAll();
      this.banderaAC = false;
      this.loadingAll = false;
      this.idComanda= data.nro_comanda;
      this.modalCtrl.open(this.modalPrintComanda, {size:'sm', backdrop: 'static', keyboard: false, centered:true})
    }else{
      this.toaster.error(`Algo paso, comuniquese con el administrador del sistema.`, '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }    
  })

  console.log('comanda', json);
  
}

closeFinal(){
    this.formComanda.reset()
    this.prods=[];
    this.webService.saveToLocalStorage('carrito',this.prods)
    this.webService.saveToLocalStorage('carLocal','0')
    this.flagCarLocal=false;
    this.closeModal();
}

changeDetalle(event, prod){
  console.log('event', event.target.value);
  prod.detalle=event.target.value;
  
  console.log('PRODS COMO QUEDA ==> ', this.prods);
  

}
sendConsulta(prod){
  console.log("PROD SE ENVIA", prod);
  console.log("PROD SE ENVIA",  this.informacion );
  let mensaje = `Estimado, requiero información del servicio ${prod.pro_nom}`
  window.open("https://wa.me/+593" +  this.information.telefonos + "?text=" + mensaje + ".", "_blank");
}


}
