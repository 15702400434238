<!-- <app-submenu></app-submenu> -->
<!-- <app-menu></app-menu> -->
<div *ngIf="!isEmpty(information) && !isEmpty(configuracion) ">
  <app-header (redirectSection)="goSection($event)" [information]="information"
      [configuracion]="configuracion"></app-header>
</div>
<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>

<section class="ml-5 mr-5 mt-5 pt-5">
  <div class="row mt-3">
    <div class="col-lg-3 text-mayuscula">
      <button type="button" id="btn-terminos" class="btn btn-sm w-100 btn-politics"
        (click)="selectInformationView(2)">Términos y
        condiciones</button>
    </div>
    <div class="col-lg-3 text-mayuscula">
      <button type="button" id="btn-privacidad" class="btn btn-sm w-100 btn-politics"
        (click)="selectInformationView(1)">Políticas de
        privacidad</button>
    </div>
    <div class="col-lg-3 text-mayuscula">
      <button type="button" id="btn-envio" class="btn btn-sm w-100 btn-politics"
        (click)="selectInformationView(3)">Políticas de
        envío y entrega</button>
    </div>
    <div class="col-lg-3 text-mayuscula">
      <button type="button" id="btn-app" class="btn btn-sm w-100 btn-politics"
        (click)="selectInformationView(5)">Políticas de privacidad aplicación</button>
    </div>

    <div class="col-lg-12 order-2 order-lg-2 mt-3 text-minuscula-texto-negro text-size-13">
      <!-- Terminos y Condiciones -->
      <div *ngIf="viewInformation.terminosCondiciones" class="p-4 bg-light rounded">
        <p class="text-center border-bottom pb-2"><strong>TÉRMINOS Y CONDICIONES</strong></p>
        <p class="text-justify">Los términos y condiciones que aplican cuando usted utiliza nuestro sitio web se
          incluyen en las siguientes
          disposiciones. Al acceder al sitio, usted acepta estar obligado por los términos y condiciones
          vigentes
          cuando usted accede a la página. El "sitio web" o "sitio" se refiere a la Página Web minorista de CLINICA AUTOMOTRIZ
          para nuestros clientes en Ecuador. "Usuario", o colectivamente "Usuarios", se refieren a
          cualquier
          persona que acceda al sitio clinicaautomotriz.com.com se refiere a CLINICA AUTOMOTRIZ y su empresa matriz,
          afiliados y licenciatarios. "Acceso" significa ver u obtener información ubicada en la Página Web de
          CLINICA AUTOMOTRIZ. "Acuerdo" se refiere a estos términos y condiciones y sus modificaciones posteriores.
          CLINICA AUTOMOTRIZ se reserva el derecho a modificar este acuerdo en cualquier momento, incluyendo la
          imposición de una cuota para acceder a ciertos materiales contenidos en el sitio. Cualquier cambio
          en este
          acuerdo se hace efectivo inmediatamente sobre el aviso de recibo del usuario por parte de CLINICA AUTOMOTRIZ.
          El aviso se puede dar a través de correo electrónico, publicación en el sitio, o cualquier otro
          medio por
          el
          cual el usuario puede obtener notificación. Los usuarios deben revisar periódicamente este acuerdo
          para
          los
          cambios. Cualquier uso del sitio después de que se han realizado cambios se considerará la
          aceptación de
          las
          condiciones cambiadas del presente acuerdo. CLINICA AUTOMOTRIZ se reserva el derecho de monitorear el
          uso del
          sitio.</p>

        <p class="text-justify">CLINICA AUTOMOTRIZ tiene el derecho exclusivo para controlar acceso, horas de uso,
          características en el sitio
          y cualquier otra información que se encuentran en el sitio. CLINICA AUTOMOTRIZ puede restringir el
          acceso a
          cualquiera o todas las porciones del sitio o eliminar cualquier información o contenido del sitio en
          cualquier momento. Si usted establece una cuenta en este sitio para comprar productos o recibir
          información
          de nosotros, usted
          es responsable de mantener la confidencialidad de su cuenta y su contraseña. Cualquier información
          personal
          proporcionada en la cuenta se regirá por la política de privacidad, que puede encontrarse en
          política de
          privacidad. Mediante el establecimiento de una cuenta, el usuario se compromete a asumir la
          responsabilidad
          de todas las actividades que ocurren en el sitio bajo su cuenta y contraseña. CLINICA AUTOMOTRIZ se
          reserva
          el
          derecho, a su sola discreción, de rechazar el servicio, limitar cantidades, cancelar órdenes o
          cancelar su
          cuenta. Cupones/vouchers no están disponibles para ninguna mercancía que se muestra en este sitio en
          caso
          el
          inventario se haya agotado. El usuario es únicamente responsable de proporcionar el equipo y
          software para
          acceder al sitio, incluyendo todas las computadoras, equipos de comunicaciones remotas, teléfono u
          otro
          equipo.</p>

        <p class="text-justify">
          Este sitio web y el contenido del sitio se proporciona "tal cual" y sin garantías de ningún tipo,
          expresas
          o
          implícitas. CLINICA AUTOMOTRIZ se reserva el derecho de corregir cualquier precio, tipográficos,
          pictóricos u
          otros errores u omisiones en el sitio. Usted reconoce, mediante el uso de este sitio, que el uso de
          este
          sitio es bajo su propio riesgo, que asume toda la responsabilidad por todos los costos de reparación
          asociados de cualquier equipo que utilice en relación con su uso de nuestro sitio, y que CLINICA AUTOMOTRIZ
          no
          será responsable por los daños y perjuicios de cualquier tipo relacionados con el indebido uso de
          este
          sitio.
        </p>

        <p class="text-center"><strong>RESCISIÓN DE CONTRATO</strong></p>
        <p class="text-justify">Estos términos y condiciones continuarán como un acuerdo entre usted y CLINICA AUTOMOTRIZ
          hasta que los suspendamos, lo cual podemos hacer en cualquier momento sin previo aviso a usted, y lo
          cual
          no
          afectará ningún derecho contractual o del consumidor que podemos haber adquirido con usted. También
          podemos
          suspender su derecho a utilizar este sitio inmediatamente y sin previo aviso si, a nuestra sola
          discreción,
          no cumple con alguno de estos términos y condiciones. Si suspendemos su derecho a utilizar este
          sitio,
          entonces usted ya no está autorizado para acceder o utilizar cualquiera de los beneficios de este
          sitio.
          Además, las restricciones que hemos impuesto en cuanto a copiar o descargar información y las
          cláusulas de
          exención y limitaciones de responsabilidad que hemos indicado en los términos y condiciones,
          sobrevivirán
          la
          terminación del acuerdo. Los términos y condiciones que hemos indicado en este sitio son el acuerdo
          completo
          entre nosotros de acuerdo a cada uno de los temas discutidos en estos términos y condiciones.</p>
      </div>

      <!-- Politicas de privacidad -->
      <div *ngIf="viewInformation.politicasPrivacidad" class="p-4 bg-light rounded">
        <p class="text-center border-bottom pb-2"><strong>POLÍTICAS DE PRIVACIDAD</strong></p>
        <p class="text-justify">Como se describe en esta Política de Privacidad, CLINICA AUTOMOTRIZ valora a sus
          clientes
          y respeta sus derechos de privacidad, cumpliendo con la Constitución Nacional y la Ley de Protección
          de
          Datos Personales. En esta política usamos el término CLINICA AUTOMOTRIZ para identificar en primer
          lugar a la
          compañía CLINICA AUTOMOTRIZ Al visitar nuestro sitio web (https://netto.loxafree.com/), usted acepta las
          políticas y
          prácticas descritas en esta Política de privacidad y los Términos Y Condiciones que se incorporan
          aquí, como
          referencia.</p>

        <p class="text-justify">Cuando usted ingresa a nuestra tienda en línea, o cuando compra nuestros
          productos por
          nuestra línea directa de WhatsApp, recopilamos información como:</p>

        <ul>
          <li class="text-justify ml-5">
            <p>Nombres y Apellidos</p>
          </li>
          <li class="text-justify ml-5">
            <p>Correo electrónico</p>
          </li>
          <li class="text-justify ml-5">
            <p>Número de teléfono</p>
          </li>
          <li class="text-justify ml-5">
            <p>Ciudad, Dirección de envío</p>
          </li>
          <li class="text-justify ml-5">
            <p>Información de facturación y tarjeta de crédito</p>
          </li>

        </ul>

        <p class="text-justify">Nosotros CLINICA AUTOMOTRIZ no vendemos, divulgamos, publicamos o alquilamos su
          nombre o
          información personal para uso de cualquiera persona externa de la empresa o de terceros y, en
          cualquier
          caso, futuro, solicitaríamos su consentimiento primero. </p>

        <p class="text-justify">Este sitio web es controlado y operado por CLINICA AUTOMOTRIZ. El número de
          teléfono
          para
          contactar a servicio
          al cliente se proporciona bajo "Reclamos de consumidores". CLINICA AUTOMOTRIZ no hace ninguna
          representación
          de
          que la información en este sitio sea apropiada o disponible para uso en ubicaciones fuera de
          Ecuador. Si
          usted elige acceder a este sitio desde una ubicación fuera de Ecuador, entonces usted lo hace por
          iniciativa
          propia, y usted es responsable de cumplir con cualquier ley local aplicable.</p>

        <p class="text-center"><strong>RESPONSABILIDAD POR LA BASE DE DATOS</strong></p>
        <p class="text-justify">CLINICA AUTOMOTRIZ es una empresa responsable del tratamiento de datos personales;
          no
          obstante, no comercializamos, vendemos, intercambiamos o transferimos dichos datos a terceros con
          fines de
          lucro. CLINICA AUTOMOTRIZ no divulga ni distribuye datos personales con fines lucrativos.</p>
        <p class="text-justify">En consecuencia, la Base de datos, en virtud del Reglamento de la Ley de
          protección de
          datos personales se considera una base interna, ya que los datos sólo se comparten con empresas del
          mismo
          grupo económico</p>
        <p class="text-justify">CLINICA AUTOMOTRIZ adopta las medidas técnicas, humanas y administrativas
          necesarias
          para proporcionar seguridad a los registros, evitando su adulteración, pérdida, consulta, uso o
          acceso no
          autorizado o fraudulento. El personal que realiza el procesamiento de los datos personales ejecutará
          los
          protocolos establecidos para proteger la seguridad de la información.</p>

        <p class="text-center"><strong>ENLACES A OTROS SITIOS</strong></p>
        <p class="text-justify">Como una conveniencia a nuestros usuarios, hemos incluido enlaces desde nuestro
          sitio
          web a sitios que son
          propiedad de las entidades que no están afiliadas con nosotros. CLINICA AUTOMOTRIZ y las entidades
          afiliadas
          con CLINICA AUTOMOTRIZ no operan o controlan el contenido de estos sitios web de terceros. Tampoco
          controlamos
          los productos o servicios ofrecidos a través de estos sitios, y CLINICA AUTOMOTRIZ no asume ninguna
          responsabilidad por los productos o servicios ofrecidos en sitios web de terceros. Cuando usted haga
          clic
          en
          un enlace de terceros al enlace de la Página Web de CLINICA AUTOMOTRIZ y accede a un sitio web de
          terceros,
          usted está abandonando el sitio web de CLINICA AUTOMOTRIZ y lo hace bajo su propio riesgo. Cualquier
          trato
          entre cualquier usuario y cualquier tercero mencionado en o a través de la Página Web de CLINICA AUTOMOTRIZ
          son
          únicamente entre dichos usuarios y terceros, y están sujetos a cualquier término, condición,
          garantía o
          representación asociada con dichos tratos. CLINICA AUTOMOTRIZ expresamente exime de cualquier
          responsabilidad
          por daños o lesiones que usted experimente mientras usted se encuentre en alguno de estos sitios web
          de
          terceros.</p>

        <p class="text-center"><strong>CONDUCTA PROHIBIDA Y CUMPLIMIENTO DE LAS LEYES</strong></p>
        <p class="text-justify">Usted expresamente acepta que no hará, ya sea personalmente o a través de un
          agente,
          cualquiera de los siguientes: </p>
        <ul>
          <li class="text-justify ml-5">
            <p>Utilizar cualquier dispositivo o cualquier otro medio para recoger información sobre otros
              usuarios.
            </p>
          </li>

          <li class="text-justify ml-5">
            <p>Transmitir, instalar, cargar o transferir cualquier virus u otro tipo de software malicioso,
              artículo
              o
              proceso al sitio que de alguna manera afecta el uso, goce o servicio del sitio o computadora
              de
              cualquier
              usuario o empleado de CLINICA AUTOMOTRIZ o cualquier otro medio utilizado para el acceder al
              sitio.</p>
          </li>

          <li class="text-justify ml-5">
            <p>Publicar cualquier material en el sitio que es ofensivo a cualquier otro usuario. CLINICA AUTOMOTRIZ
              mantiene
              el derecho exclusivo de determinar qué es considerado ofensivo.</p>
          </li>

          <li class="text-justify ml-5">
            <p>Publicar o almacenar en el sitio cualquier contenido que viole o infrinja los derechos de
              propiedad
              intelectual u otros derechos de terceros (incluyendo, pero no limitado a derechos de autor,
              marcas
              registradas, secretos comerciales, patentes, privacidad y derechos de publicidad).</p>
          </li>

          <li class="text-justify ml-5">
            <p>Modificar la información, incluyendo encabezados, encontradas en el sitio.</p>
          </li>

          <li class="text-justify ml-5">
            <p>Transmitir, instalar, cargar, publicar o transferir al sitio cualquier publicidad no
              autorizada o
              comunicación.</p>
          </li>

          <li class="text-justify ml-5">
            <p>Participar en cualquier acción que es perjudicial para el uso y disfrute del sitio. CLINICA AUTOMOTRIZ
              mantiene el derecho exclusivo de determinar qué es considerado perjudicial.</p>
          </li>

          <li class="text-justify ml-5">
            <p>Utilizar el sitio para propósitos ilegales o difamatorios.</p>
          </li>

          <li class="text-justify ml-5">
            <p>Transmitir, instalar, cargar, publicar o transferir cualquier información en violación de
              cualquier
              ley
              local, estatal, nacional o internacional, o publicar cualquier información que podría
              resultar en
              disturbios
              civiles.</p>
          </li>

          <li class="text-justify ml-5">
            <p>Acceder o intentar acceder, la información personal de otro usuario que se almacena en este
              sitio.
            </p>
          </li>

          <li class="text-justify ml-5">
            <p>Permitir que cualquier persona pueda acceder a este sitio utilizando su contraseña.</p>
          </li>

          <li class="text-justify ml-5">
            <p>Tratar de acceder o acceder a este sitio con la contraseña de otro usuario.</p>
          </li>

          <li class="text-justify ml-5">
            <p>Los usuarios de este sitio son responsables de cumplir con todas las leyes locales
              aplicables.</p>
          </li>
        </ul>

      </div>

      <!-- Politicas de envio y Entrega -->
      <div *ngIf="viewInformation.politicasEnvioEntrega" class="p-4 bg-light rounded">
        <p class="text-center border-bottom pb-2"><strong>POLÍTICAS DE ENVÍO Y ENTREGA</strong></p>
        <p class="text-justify">Después de la compra, el tiempo máximo para recibir su pedido será 48 a 72
          horas, sólo
          teniendo en cuenta días laborables. Si hay días de feriado o coincide con fines de semana por el
          tiempo de
          envío serán suspendidos hasta el siguiente día hábil. El proceso de entrega del producto se los
          realiza por
          la empresa servientrega directamente a la dirección que detalle el cliente en su compra.
        </p>
        <p class="text-justify">Las compras realizadas a través de la página web CLINICA AUTOMOTRIZ serán
          entregados al
          cliente en el lugar de destino que se solicita, siempre que sea dentro del área de cobertura de
          SERVIENTREGA
          la tienda se pondrá en contacto con el cliente a través de una llamada telefónica o por correo
          electrónico
          para informarle que su pedido está en camino, para que el cliente puede estar disponible para la
          entrega.
        </p>
        <p class="text-center"><strong>DEVOLUCIÓN Y POLITICAS DE INTERCAMBIO</strong></p>
        <p class="text-justify">Si selecciona un tamaño incorrecto, puede volver e intercambiar mercancía sin
          uso, a
          nuestra tienda de CLINICA AUTOMOTRIZ, cumpliendo con lo siguiente:</p>
        <ul>
          <li class="text-justify ml-5">
            <p>Entregar el artículo a devolver. Artículos deben ser devueltos en su caja original (empaque)
              y sin
              uso. Elementos con daños o pérdida de cualquier tipo indica desgaste natural o uso indebido
              no son
              aceptados.</p>
          </li>
          <li class="text-justify ml-5">
            <p>El artículo debe ser devuelto dentro de 30 días hábiles de la compra (lunes a viernes cuentan
              cómo días
              hábiles).</p>
          </li>
          <li class="text-justify ml-5">
            <p>El recibo de compra original o una copia de su E-mail de confirmación</p>
          </li>
          <li class="text-justify ml-5">
            <p>Una forma de identificación personal y la tarjeta de crédito utilizada para la compra
              original.</p>
          </li>
        </ul>
        <p class="text-justify">El cliente puede reemplazar el producto en la tienda CLINICA AUTOMOTRIZ por:</p>
        <ul>
          <li class="text-justify ml-5">
            <p>El mismo artículo en un tamaño diferente, si está disponible en la tienda.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Otro artículo disponible en la tienda con el mismo precio.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Otro artículo disponible en la tienda con un precio más alto (en cuyo caso el cliente pagará
              la
              diferencia).</p>
          </li>
          <li class="text-justify ml-5">
            <p>CLINICA AUTOMOTRIZ. no reembolsará la diferencia de dinero si el cliente eligió un artículo
              diferente con
              un precio menor que el original</p>
          </li>
        </ul>
        <p class="text-justify">Para los intercambios en la tienda, en el caso de que el artículo original se
          haya
          adquirido con precio promocional, si quiere cambiar el artículo original, debe ser intercambiado por
          el
          mismo artículo en otro tamaño para garantizar el precio. Si desea un producto diferente, el nuevo
          producto
          debe ser el mismo precio del original. Si no es así, se dará un crédito de mercancía por la cantidad
          de
          dinero efectivamente pagado (precio promocional del producto original). </p>

        <p class="text-center"><strong>PÓLIZA DE GARANTÍA</strong></p>
        <p class="text-justify">Si el producto tiene un defecto de fabricación, puede regresarlo e
          intercambiarlo en
          nuestra tienda de CLINICA AUTOMOTRIZ dentro de 30 días hábiles (lunes a viernes cuentan cómo días
          hábiles).
          Para aplicar la garantía en nuestra tienda de CLINICA AUTOMOTRIZ, usted deberá cumplir con lo
          siguiente:
        </p>
        <ul>
          <li class="text-justify ml-5">
            <p>El artículo debe ser devuelto y estar bajo la garantía de 30 días desde que se adquirió.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Debe presentar el recibo de venta original o factura.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Mostrar una identificación personal y la tarjeta de crédito utilizada en la compra original.
            </p>
          </li>
        </ul>
        <p class="text-justify">CLINICA AUTOMOTRIZ puede reemplazar o reparar el producto que presenta falla
          durante el
          período de garantía como consecuencia de un defecto de diseño o fabricación. El gerente de tienda
          evaluará
          el tema y decidirá si califica para el reemplazo debido a un defecto de fabricación.
        </p>
        <p class="text-justify">El nuevo elemento intercambiado tiene una garantía de 30 días contados a partir
          de la
          fecha de la sustitución y será aplicable la misma garantía del producto original. En caso de que el
          nuevo
          producto presente nuevamente daño, el gerente de tienda evaluará el tema y decidirá si califica para
          el
          reembolso, usando la cantidad de dinero abonado.
        </p>
        <p class="text-justify">
          <strong>
            La existencia de los artículos, precios, detalles y disponibilidad de los productos mostrados en
            el
            sitio web están sujetos a cambio sin previo aviso. Aplica restricciones.
          </strong>
        </p>


        <p class="text-center"><strong>PROMOCIONES</strong></p>
        <p class="text-justify">De vez en cuando, la Página Web de CLINICA AUTOMOTRIZ cuenta con varias
          promociones.
          Estas promociones pueden incluir ofertas, cupones u otros tipos similares de promociones. Hemos
          establecido
          algunas reglas simples que se aplican a estas promociones:</p>
        <strong>
          <p>Promociones</p>
        </strong>
        <ul>
          <li class="text-justify ml-5">
            <p>Todas las promociones están sujetas a estos términos y condiciones como se muestra en este
              sitio.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Nos reservamos el derecho de limitar cantidades de compra.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Todas las promociones son por tiempo limitado, y las fechas de la promoción aparecen en este
              sitio.
              Si no se especifican las fechas para una promoción en particular, la promoción finalizará
              después de
              14
              días.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Varias promociones tienen requisitos de monto mínimo de compra. Dichos requisitos se
              establecen como
              parte de la descripción de la promoción en línea.</p>
          </li>
          <li class="text-justify ml-5">
            <p>A veces, llevamos a cabo simultáneamente dos o más promociones. Nosotros indicamos como parte
              de la
              descripción en línea de una promoción si puede utilizarse en combinación con otra promoción.
            </p>
          </li>
          <li class="text-justify ml-5">
            <p>A menos que se indique lo contrario como parte de la descripción de la promoción, todas las
              promociones requieren una compra.</p>
          </li>
        </ul>
        <strong>
          <p>Cupones</p>
        </strong>
        <ul>
          <li class="text-justify ml-5">
            <p>Todos los términos en un cupón impreso que indican que puede ser usado para compras de tienda
              web
              también aplican al uso del cupón a través de la Página Web.</p>
          </li>
          <li class="text-justify ml-5">
            <p>No se permite el uso de cupones para productos que no sean los indicados.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Usted es responsable de cualquier impuesto aplicable.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Cupones emitidos en Ecuador sólo son válidos para la tienda CLINICA AUTOMOTRIZ.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Las devoluciones hechas por artículos comprados a través de este sitio serán hechas al precio
              que se
              pagó por dicho artículo, no al precio completo.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Sólo un cupón puede usarse para cada transacción hecha en la página web a menos que se
              indique lo
              contrario en los términos y condiciones del cupón.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Una transacción ocurre cuando usted completa el proceso de pago.</p>
          </li>
          <li class="text-justify ml-5">
            <p>Los cupones no son canjeables por dinero en efectivo.</p>
          </li>
          <li class="text-justify ml-5">
            <p>La página web no acepta cupones de emisores distintos a CLINICA AUTOMOTRIZ y sus entidades
              afiliadas.
            </p>
          </li>
        </ul>
        <strong>
          <p>Artículos Gratis</p>
        </strong>
        <p class="text-justify">Es posible que reciba uno o más artículos "gratis" con cada transacción en línea
          conforme a los
          términos y condiciones de las promociones vigentes en el momento de la transacción y estos artículos
          "gratis" no pueden ser devueltos excepto las promociones "Compre 2, obtenga 1 gratis" como se
          describe a
          continuación.</p>
        <ul>
          <li class="text-justify ml-5">
            <p>Cuando usted recibe un artículo «gratis» como parte de una promoción, no hay cargos de manejo
              o envío
              por ese artículo "gratis".</p>
          </li>
          <li class="text-justify ml-5">
            <p>La excepción a las reglas de artículo «gratis» descritas arriba es la promoción "Compre 2,
              obtenga 1
              gratis", ofrecida ocasionalmente a nuestros clientes. Si usted coloca una orden elegible y
              recibe un
              artículo gratis en esta promoción, se le cobrará cargos de envío y manejo por el artículo
              gratis. Si
              no
              estás satisfecho con el ajuste o la calidad del artículo gratis en este caso su único
              remedio es
              intercambiar el artículo por otro artículo de igual o menor valor a precio regular. Consulte
              nuestro
              programa de devoluciones e intercambios para más detalles.</p>
          </li>
        </ul>
        <strong>
          <p>Fijación de precios</p>
        </strong>
        <p class="text-justify">Precios en este sitio web pueden diferir de los precios en tiendas de CLINICA AUTOMOTRIZ, y los
          precios en este sitio se aplican exclusivamente a este sitio web y no se aplican en
          tiendas
          de
          CLINICA AUTOMOTRIZ.</p>
        <strong>
          <p>Garantias</p>
        </strong>
        <p class="text-justify">La mercancía comprada en este sitio web está garantizada contra defectos en
          materiales y mano de obra durante treinta días hábiles siguientes a la fecha de compra en su recibo
        </p>
      </div>

            <!-- Politicas de Aplicacion -->
            <div *ngIf="viewInformation.politicaAPP" class="p-4 bg-light rounded">
              <p class="text-center border-bottom pb-2"><strong>POLÍTICA DE PRIVACIDAD PARA APLICACIONES MÓVILES (APPS)</strong></p>
              <p>Fecha última actualización: 24 de noviembre del 2023</p>
              <p class="text-justify">De conformidad con el Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (Reglamento General de Protección de Datos - RGPD), Punto Pymes, informa	a	los usuarios de la aplicación Pepito y María (en adelante, la Aplicación), acerca del tratamiento de los datos personales, que ellos voluntariamente hayan facilitado durante el proceso de registro, acceso y utilización del servicio.</p>
      
              <p class="fw-bold" >1.	IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO.</p>
              <p class="pl-3" >Punto Pymes,	con	CIF/NIF	nº:	1191732789001	y	domicilio	a	efectos	de	notificaciones	en:
                Loja, Azuay 12660 Entre Bernardo Valdivieso y Olmedo, e inscrita en el Registro Mercantil de Loja Tomo S/N, Folio S/N, Sección S/N ª, Hoja S/N, inscripción ª (en adelante, el Responsable del Tratamiento), es la entidad responsable del tratamiento de los datos facilitados por los clientes de la Aplicación (en adelante, el/los Usuario/s).
              </p>
              <p class="fw-bold" >2.	FINALIDAD DEL TRATAMIENTO DE DATOS.</p>
              <p class="pl-3" >
                Para proceder al registro, acceso y posterior uso de la Aplicación, el Usuario deberá facilitar de forma voluntaria, datos de carácter personal (esencialmente, identificativos y de contacto), los cuales serán incorporados a soportes automatizados titularidad de Punto Pymes.
              </p>
              <p class="pl-3" >
                La recogida, almacenamiento, modificación, estructuración y en su caso, eliminación, de los datos proporcionados por los Usuarios, constituirán operaciones de tratamiento llevadas a cabo por el Responsable, con la finalidad de garantizar el correcto funcionamiento de la Aplicación, mantener la relación de prestación de servicios y/o comercial con el Usuario, y para la gestión, administración, información, prestación y mejora del servicio.              </p>
              <p class="pl-3" >
                Los datos personales facilitados por el Usuario especialmente, el correo electrónico o e-mail- podrán emplearse también para remitir boletines (newsletters), así como comunicaciones comerciales de promociones y/o publicidad de la Aplicación, siempre y cuando, el Usuario haya prestado previamente su consentimiento expreso para la recepción de estas comunicaciones vía electrónica              </p>
      
              <p class="fw-bold" >3.	LEGITIMACIÓN.</p>
              <p class="pl-3" >
                El tratamiento de los datos del Usuario, se realiza con las siguientes bases jurídicas que legitiman el mismo:   
              </p>
         
                <ul class="pl-5" >
                  <li>
                    La solicitud de información y/o la contratación de los servicios de la Aplicación, cuyos términos y condiciones se pondrán a disposición del Usuario en todo caso, con carácter previo, para su expresa aceptación.
                  </li>
                  <li>
                  	El consentimiento libre, específico, informado e inequívoco del Usuario, poniendo a su disposición la presente política de privacidad, que deberá aceptar mediante una declaración o una clara acción afirmativa, como el marcado de una casilla dispuesta al efecto     
                  </li>
                </ul>

              <p class="pl-3" >
                En caso de que el Usuario no facilite a Punto Pymes sus datos, o lo haga de forma errónea o incompleta, no será posible proceder al uso de la Aplicación.      
              </p>

              <p class="fw-bold" >4.	CONSERVACIÓN DE LOS DATOS PERSONALES.</p>
              <p>
                Los datos personales proporcionados por el Usuario, se conservarán en los sistemas y bases de datos del Responsable del Tratamiento, mientras aquél continúe haciendo uso de la Aplicación, y siempre que no solicite su supresión.
              </p>
              <p>
                Con el objetivo de depurar las posibles responsabilidades derivadas del tratamiento, los datos se conservarán por un período mínimo de cinco años.
              </p>
              <p class="fw-bold" >5.	DESTINATARIOS</p>
              <p>
                Los datos no se comunicarán a ningún tercero ajeno a Punto Pymes, salvo obligación legal o en cualquier caso, previa solicitud del consentimiento del Usuario.
              </p>
              <p>
                De otra parte, Punto Pymes podrá dar acceso o transmitir los datos personales facilitados por el Usuario, a terceros proveedores de servicios, con los que haya suscrito acuerdos de encargo de tratamiento de datos, y que únicamente accedan a dicha información para prestar un servicio en favor y por cuenta del Responsable.
              </p>
              <p class="fw-bold" >6.	RETENCIÓN DE DATOS.</p>
              <p>
                Punto Pymes, informa al Usuario de que, como prestador de servicio de alojamiento de datos y en virtud de lo establecido en la Ley 34/2002 de 11 de julio de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI), retiene por un período máximo de 12 meses la información imprescindible para identificar el origen de los datos alojados y el momento en que se inició la prestación del servicio.
              </p>
              <p>
                La retención de estos datos no afecta al secreto de las comunicaciones y sólo podrán ser utilizados en el marco de una investigación criminal o para la salvaguardia de la seguridad pública, poniéndose a disposición de los jueces y/o tribunales o del Ministerio que así los requiera.
              </p>
              <p>
                La comunicación de datos a las Fuerzas y Cuerpos de Seguridad del Estado, se hará en virtud de lo dispuesto por la normativa sobre protección de datos personales, y bajo el máximo respeto a la misma.
              </p>
      
              <p class="fw-bold" >7.	PROTECCIÓN DE LA INFORMACIÓN ALOJADA.</p>
              <p>
                El Responsable del Tratamiento, adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos.
              </p>
              <p>
                Si bien el Responsable, realiza copias de seguridad de los contenidos alojados en sus servidores, sin embargo no se responsabiliza de la pérdida o el borrado accidental de los datos por parte de los Usuarios. De igual manera, no garantiza la reposición total de los datos borrados por los Usuarios, ya que los citados datos podrían haber sido suprimidos y/o modificados durante el periodo de tiempo transcurrido desde la última copia de seguridad.
              </p>
              <p>
                Los servicios facilitados o prestados a través de la Aplicación, excepto los servicios específicos de backup, no incluyen la reposición de los contenidos conservados en las copias de seguridad realizadas por el Responsable del Tratamiento, cuando esta pérdida sea imputable al usuario; en este caso, se determinará una tarifa acorde a la complejidad y volumen de la recuperación, siempre previa aceptación del usuario. La reposición de datos borrados sólo está incluida en el precio del servicio cuando la pérdida del contenido sea debida a causas atribuibles al Responsable.
              </p>
              <p class="fw-bold" >8.	EJERCICIO DE DERECHOS.</p>
              <p>
                Punto Pymes, informa al Usuario de que le asisten los derechos de acceso, rectificación, limitación, supresión, oposición y portabilidad, los cuales podrá ejercitar mediante petición dirigida al correo electrónico: puntopymes&#64;sofpymes.com
              </p>
              <p>
                Asimismo, el Usuario tiene derecho a revocar el consentimiento inicialmente prestado, y a interponer reclamaciones de derechos frente a la Agencia Española de Protección de Datos (AEPD).
              </p>
              <p class="fw-bold" >9.	COMUNICACIONES COMERCIALES POR VÍA ELECTRÓNICA.</p>
              <p>
                En aplicación de la LSSI (Ley de Servicios de la Sociedad de la Información), Punto Pymes, no enviará comunicaciones publicitarias o promocionales por correo electrónico u otro medio de comunicación electrónica equivalente que previamente no hubieran sido solicitadas o expresamente autorizadas por los destinatarios de las mismas.
              </p>
              <p>
                En el caso de usuarios con los que exista una relación contractual, jurídica o de servicios previa, el Responsable del Tratamiento, sí está autorizado al envío de comunicaciones comerciales referentes a productos o servicios del Responsable que sean similares a los que inicialmente fueron objeto de contratación con el cliente.
              </p>
              <p>
                En caso de que el Usuario quiera darse de baja a la hora de recibir las citadas comunicaciones, podrá hacerlo remitiendo su voluntad por e-mail al correo electrónico: puntopymes&#64;sofpymes.com
              </p>
      
            </div>
    </div>
  </div>

</section>

<!-- <app-footer></app-footer> -->
